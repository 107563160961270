/* utilities */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p {
  font-family: "Roboto", sans-serif;
}
.overlay-primary::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
}
.overlay-secondary::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #14287b;
  opacity: 0.8;
  z-index: -1;
}
.rtin-title {
  font-size: 36px;
  color: #14287b;
  line-height: 1.3;
  margin-bottom: 18px;
}
.custom-button {
  background-color: #fef22e;
  color: rgb(75, 72, 72);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  padding: 14px 25px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 2;
  transition: all 0.5s ease-out;
}

.custom-button::before {
  content: "";
  height: 100%;
  width: 0;
  background-color: #14287b;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all 0.45s ease-out;
}

.custom-button:hover::before {
  width: 100%;
}
.custom-button:hover {
  color: white;
}
.custom-shadow {
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.27);
}

.loader {
  position: relative;
  display: flex;
}
.loader:before,
.loader:after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  color: #14287b;
  background: currentColor;
  box-shadow: 50px 0, -50px 0;
  animation: left 1s infinite ease-in-out;
}
.loader:after {
  color: #ff3d00;
  animation: right 1.1s infinite ease-in-out;
}

@keyframes right {
  0%,
  100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

@keyframes left {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* slider css  */
/* .swiper {
  width: 100%;
  height: 100%;
  padding: 60px 0;
} */

/* .swiper-slide {
  margin: 0px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 6px;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  width: 20px;
  border-radius: 5px;
}

.brand-slider .swiper-slide {
  margin: 0;
}

@media only screen and (max-width: 570px) {
  .swiper-slide {
    margin: 0;
  }
} */
